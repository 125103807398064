<template>
  <div class="vx-row">
    <vs-popup title="Nouveau compte sms" :active.sync="popupCreateCompte">
      <span class="text-xl font-semibold" style="">
        SMS DISPONIBLE COMPTE :
      </span>
      <span class="ml-2 text-xl font-semibold" style="color: red">
        {{ balance ? balance.availableUnits : '' }}
      </span>
      <span v-if="balance && balance.status === 'ACTIVE'" class="ml-2 text-xl font-semibold" style="color: green">
        / STATUS DU COMPTE: {{ balance ? balance.status : '' }}
      </span>
      <span v-else class="ml-2 text-xl font-semibold" style="color: red">
        / STATUS DU COMPTE: {{ balance ? balance.status : '' }}
      </span>
      <div class="vx-row">
        <div class="vx-col w-full mt-5">
          <vs-select label="Officine" autocomplete class="w-full" v-model="IdOfficine">
            <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
          </vs-select>
        </div>
        <div class="vx-col w-full mt-5">
          <vs-input disabled class="w-full" type="text" label="Pays" :value="pays" @change.native="pays=$event.target.value" />
        </div>
        <div class="vx-col w-full mt-5">
          <vs-input class="w-full" type="number" label="Unités" :value="unites" @change.native="unites=$event.target.value" />
        </div>
        <div class="vx-col w-full mt-5">
          <vs-select label="Status" autocomplete class="w-full" v-model="status">
            <vs-select-item  class="w-full" :key="index" :value="item" :text="item" v-for="(item,index) in ['ACTIVE', 'INACTIF']" />
          </vs-select>
        </div>
        <div class="vx-col w-full mt-5">
          <div class="flex flex-wrap items-center justify-end" slot="footer">
            <vs-button class="mr-4" @click="createCompteSmsOfficine">NOUVEAU</vs-button>
            <vs-button type="border" color="danger" @click="popupCreateCompte = false">ANNULER</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
    <vs-popup title="Modifier compte sms" :active.sync="popupEditCompte">
      <span class="text-xl font-semibold" style="">
        SMS DISPONIBLE COMPTE :
      </span>
      <span class="ml-2 text-xl font-semibold" style="color: red">
        {{ balance ? balance.availableUnits : '' }}
      </span>
      <span v-if="balance && balance.status === 'ACTIVE'" class="ml-2 text-xl font-semibold" style="color: green">
        / STATUS DU COMPTE: {{ balance ? balance.status : '' }}
      </span>
      <span v-else class="ml-2 text-xl font-semibold" style="color: red">
        / STATUS DU COMPTE: {{ balance ? balance.status : '' }}
      </span>
      <div class="vx-row">
        <div class="vx-col w-full mt-5">
          <vs-select label="Officine" autocomplete class="w-full" v-model="IdOfficine">
            <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
          </vs-select>
        </div>
        <div class="vx-col w-full mt-5">
          <vs-input disabled class="w-full" type="text" label="Pays" :value="pays" @change.native="pays=$event.target.value" />
        </div>
        <div class="vx-col w-full mt-5">
          <vs-input class="w-full" type="number" label="Unités" :value="unites" @change.native="unites=$event.target.value" />
        </div>
        <div class="vx-col w-full mt-5">
          <vs-select label="Status" autocomplete class="w-full" v-model="status">
            <vs-select-item  class="w-full" :key="index" :value="item" :text="item" v-for="(item,index) in ['ACTIVE', 'INACTIF']" />
          </vs-select>
        </div>
        <div class="vx-col w-full mt-5">
          <div class="flex flex-wrap items-center justify-end" slot="footer">
            <vs-button class="mr-4" @click="updateCompteSmsOfficine">MODIFIER</vs-button>
            <vs-button type="border" color="danger" @click="popupEditCompte = false">ANNULER</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
    <div class="vx-col w-full">
      <vx-card>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-table search stripe pagination max-items="20" noDataText="Aucune données n'a été enregistrée" :data="comptes">
              <template slot="header">
                <vs-button @click="popupCreateCompte = true">CRÉER UN COMPTE SMS</vs-button>
              </template>
              <template slot="thead">
                <vs-th>Officine</vs-th>
                <vs-th>Pays</vs-th>
                <vs-th>Unités</vs-th>
                <vs-th>Status</vs-th>
                <vs-th>Actions</vs-th>
              </template>
              <template slot-scope="{data}">
                <tbody>
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                      {{ displaysOfficineName(tr.IdOfficine) ? displaysOfficineName(tr.IdOfficine).NomOffic : '' }}
                    </vs-td>
                    <vs-td>
                      {{ tr.pays }}
                    </vs-td>
                    <vs-td>
                      {{ tr.unites }}
                    </vs-td>
                    <vs-td>
                      {{ tr.status }}
                    </vs-td>
                    <vs-td>
                      <feather-icon title="Editer" icon="Edit3Icon" svgClasses="w-5 h-5 hover:text-primary stroke-current ml-2" @click.stop="editCompte(tr)" />
                      <feather-icon title="Supprimer" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="confirmDeleteRecord(tr._id)" />
                    </vs-td>
                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      activeUserInfos: null,
      IdOfficine: null,
      IdUser: null,
      balance: null,
      popupCreateCompte: false,
      popupEditCompte: false,

      idCompte: null,
      pays: 'CIV',
      unites: 0,
      status: 'ACTIVE'
    }
  },
  computed: {
    comptes () {
      return this.$store.state.sms_marketing.comptes
    },
    officines () {
      return this.$store.state.officine.officines
    }
  },
  methods: {
    displaysOfficineName (id) {
      return this.$store.getters['officine/getOfficineById'](id)
    },
    editCompte (data) {
      this.IdOfficine = data._id
      this.idCompte = data._id
      this.pays = data.pays
      this.unites = data.unites
      this.status = data.status

      this.popupEditCompte = true
    },
    confirmDeleteRecord (id) {
      this.idCompte = id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Supprimer',
        text: 'Supprimer ce compte ?',
        acceptText: 'Supprimer',
        accept: this.deleteRecord
      })
    },
    createCompteSmsOfficine () {
      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.IdUser,
        pays: this.pays,
        unites: this.unites,
        status: this.status
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('sms_marketing/createCompteSmsOfficine', payload)
        .then(() => {
          this.resetDataForm()
          this.$vs.notify({
            title: 'success',
            text: 'success !',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
    },
    updateCompteSmsOfficine () {
      const payload = {
        id: this.idCompte,
        IdOfficine: this.IdOfficine,
        pays: this.pays,
        unites: this.unites,
        status: this.status
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('sms_marketing/updateCompteSmsOfficine', payload)
        .then(() => {
          this.resetDataForm()
          this.popupEditCompte = false
          this.$vs.notify({
            title: 'success',
            text: 'success !',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
    },
    deleteRecord () {
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('sms_marketing/deleteCompteSmsOfficine', this.idCompte)
        .then(() => {
          this.$vs.notify({
            title: 'Supprimer',
            text: 'supprimer!',
            iconPack: 'feather',
            icon: 'icon-trash',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
    },
    resetDataForm () {
      this.IdOfficine = null
      this.idCompte = null
      this.unites = 0
      this.pays = 'CIV'
      this.status = 'ACTIVE'
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.IdOfficine = this.activeUserInfos.IdOfficine
      this.IdUser = this.activeUserInfos._id

      //this.getRepertoiresByOfficine(JSON.parse(localStorage.getItem('userInfo')).IdOfficine)
    },
    getCompteSmsOfficines () {
      this.$store.dispatch('sms_marketing/getCompteSmsOfficines')
        .then(() => {

        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getBalanceSMS () {
      this.$store.dispatch('sms_marketing/getBalanceSMS')
        .then((response) => {
          this.balance = response.data.balance[0]
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    }
  },
  created () {
    this.activeUserInfo()
    this.getCompteSmsOfficines()
    this.getOfficines()
    this.getBalanceSMS()
  }
}
</script>
